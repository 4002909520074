@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.MuiTypography-heading1 .styled, .MuiTypography-heading2 .styled{
  color:#5CB5A2;
  position:relative;
}

.MuiTypography-heading1 .styled::after{
  content:'';
  display: inline-block;
  position:absolute;
  bottom: -47px;
  left:-10px;
  right:0;
  width: 200px; 
  height: 40px; 
  border: solid 3px#FFC27A; 
  border-color: #FFC27A transparent transparent transparent;
  border-radius: 50%/20px 20px 0 0; 
}

.slick-dots{
  bottom: -65px !important;
  text-align: left !important;
}

.slick-dots li .dot{
  display: block;
  width: 10px;
  height: 10px;
  background: #DDE0E4;
  border-radius: 50%;
}
.slick-dots li.slick-active{
  margin: 0 15px 0 0
}
.slick-dots li.slick-active .dot{
  width: 30px;
  height: 10px;
  border-radius:10px;
  background: #5CB5A2;
}

  .slick-next {
  right: 15px !important;
}

.slick-prev {
  right: 65px !important;
  left:unset !important
}

 .slick-arrow{
  top:112% !important;
  width:40px !important;
  height:40px !important;
  font-size: 20px !important;
  color:white !important;
  background-color: #5CB5A2 !important;
  border-radius:50% !important;
  display:flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 100 !important;
  border: 2px solid #5CB5A2 !important;
}
.slick-prev:before, .slick-next:before{
  content:'' !important
} 

.slick-arrow.slick-disabled{
  color: #5CB5A2 !important;
  background-color: transparent !important;
  cursor:default !important
}

@keyframes moveImagetoTop {
  from {
    transform: translateY(230px);
  }
  to {
    transform: translateY(80px);
  }
}

@keyframes moveImagetoBottom {
  from {
    transform: translateY(40px);
  }
  to {
    transform: translateY(210px);
  }
}

.hp_image_1 {
  position: absolute;
  left: 19%;
  width: 22%;
  z-index: 1;
  animation: moveImagetoTop 2s linear forwards;
}

.hp_image_2 {
  position: absolute;
  left:42%;
  width: 22%;
  z-index: 1;
  animation: moveImagetoBottom 2s linear forwards;
}

.hp_image_3 {
  position: absolute;
  right: 13%;
  width:22%;
  z-index: 1;
  animation: moveImagetoTop 2s linear forwards;
}

.react-tel-input input[type=text], .react-tel-input input[type=tel]{
  padding-left:50px !important
}
.react-tel-input .flag-dropdown{
  left:10px
}

@media (max-width: 1400px) {

  @keyframes moveImagetoTop {
    from {
      transform: translateY(150px);
    }
    to {
      transform: translateY(62px);
    }
  }
  

  @keyframes moveImagetoBottom {
    from {
      transform: translateY(20px);
    }
    to {
      transform: translateY(160px);
    }
  }
}
@media (max-width: 1024px) {

  @keyframes moveImagetoTop {
    from {
      transform: translateY(150px);
    }
    to {
      transform: translateY(52px);
    }
  }

  @keyframes moveImagetoBottom {
    from {
      transform: translateY(20px);
    }
    to {
      transform: translateY(120px);
    }
  }
}


@media (max-width: 640px) {
 
  .MuiTypography-heading1 .styled::after{
    bottom: -27px; 
    left: -5px;    
    width: 150px;  
    height: 20px; 
    border-radius: 50%/10px 10px 0 0; 
  }

  @keyframes moveImagetoTop {
    from {
      transform: translateY(130px);
    }
    to {
      transform: translateY(40px);
    }
  }

  @keyframes moveImagetoBottom {
    from {
      transform: translateY(20px);
    }
    to {
      transform: translateY(100px);
    }
  }



}